'use strict';

// Based on the generic feedback tool

// Converted from jQuery to use vanilla JS, could be refactored and improved significantly.

function sendRating(rating, feedback_text) {
    var result = false

    var feedback = {
        score: rating,
        message: feedback_text,
        url: window.location.href
      };

    var request = new XMLHttpRequest();
    var url = "/feedback-submit";
    request.open("POST", url, true);
    request.setRequestHeader("Content-Type", "application/json");
    request.onreadystatechange = function () {
        if (request.readyState === 4) {
            if(request.status === 200) {
                console.log("Feedback sent");
                result = true;
            }
            else {
                console.log("Feedback not sent");
                result = false;
            }
            ratingSent(result);
        }
    };

    var data = JSON.stringify(feedback);
    request.send(data);
};

function ratingSent(result){
    document.querySelector('.page-feedback--rating').classList.add('hide');
    document.querySelector('.page-feedback--heading').classList.add('hide');
    document.querySelector('.page-feedback--content-unsubmitted').classList.add('hide');

    if (result){
        document.querySelector('.page-feedback--content-submitted').classList.remove('hide');
    setTimeout(function () { document.querySelector('#page-feedback').classList.toggle('open'); }, 1200);
    } else {
        document.querySelector('.page-feedback--content-error').classList.remove('hide');
    }
};

function setup() {
    var feedback = document.querySelector('#page-feedback');
    var sideHeader = document.querySelector('.page-feedback-side-header');
    var positive = document.querySelector('.page-feedback--positive');
    var negative = document.querySelector('.page-feedback--negative');

    var positiveMessage = document.querySelector('.page-feedback--positive-message');
    var negativeMessage = document.querySelector('.page-feedback--negative-message');

    var outcome = document.querySelector('.page-feedback--outcome');
    var additionalMessage = document.querySelector('.page-feedback--content');
    var feedbackText = document.querySelector('textarea#additional');
    var submittedMessage = document.querySelector('.page-feedback--content-submitted');
    var submitButton = document.querySelector('.page-feedback--submit');

    var score = 0;

    //Show the fedback tool, which will be hidden by html on loading in case GA isn't present.
    feedback.classList.remove('hide');

    // Then hide the bits of the tool that shouldn't be seen on first use.
    outcome.classList.add('hide');
    additionalMessage.classList.add('hide');
    submittedMessage.classList.add('hide');
    positiveMessage.classList.add('hide');
    negativeMessage.classList.add('hide');

    sideHeader.addEventListener('click', function (event) {
        event.preventDefault();
        feedback.classList.toggle('open');
    });


    // TODO: Combine these positive and negative click functions, they are almost identical.
    positive.addEventListener('click', function (event) {
        event.preventDefault();
        negative.classList.remove('active');
        positive.classList.add('active');
        positiveMessage.classList.remove('hide');
        negativeMessage.classList.add('hide');
        additionalMessage.classList.remove('hide');
        score = this.getAttribute('data-score');
    });

    negative.addEventListener('click', function (event) {
        event.preventDefault();
        negative.classList.add('active');
        positive.classList.remove('active');
        positiveMessage.classList.add('hide');
        negativeMessage.classList.remove('hide');
        additionalMessage.classList.remove('hide');
        score = this.getAttribute('data-score');
    });

    submitButton.addEventListener('click', function (event) {
        event.preventDefault();
        sendRating(score, feedbackText.value);
    });
}

document.addEventListener("DOMContentLoaded", function (e) {
    if(document.querySelector('#page-feedback')) {
    setup();
    };
});
