var burger = document.getElementById("menu-button");
var menuPanel = document.getElementById("menu-panel");

burger.checked = false;
burger.addEventListener("click", function() {
    toggleMenu(menuPanel.classList.contains("invisible"));
});

window.addEventListener('mouseup', function(e) {
    if (event.target != burger) {
        toggleMenu(false);
    }
});

function toggleMenu(isVisible) {
    if (isVisible) {
        menuPanel.classList.add("visible");
        menuPanel.classList.remove("invisible");
        burger.checked = true;
    } else {
        menuPanel.classList.add("invisible");
        menuPanel.classList.remove("visible");
        burger.checked = false;
    }
}
